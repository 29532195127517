<template>
  <div>
    <div class="compont_p">我的电子发票</div>
    <el-form :inline="true" :model="form1" style="margin-top: 20px">
      <el-form-item label="相关订单:">
        <el-input style="width: 150px" clearable v-model="form1.kw" placeholder="相关订单"></el-input>
      </el-form-item>
      <el-form-item label="发生时间:">
        <el-date-picker v-model="value1" type="daterange" @change="changeTime" value-format="yyyy-MM-dd" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"> </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" class="custombtn" @click="getEinvoice">筛选</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="tableData" style="margin-top: 20px">
      <el-table-column prop="ddbh" label="订单编号" align="center" />
      <el-table-column prop="taitou" label="发票抬头" align="center" />
      <!-- <el-table-column prop="invoice_no" label="发票号码" align="center" /> -->
      <el-table-column prop="date_str" label="开票日期" align="center" />
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <span style="color: #0abc64; cursor: pointer" @click="handleClick(scope.row)">下载</span>
          <!-- <div class="check flex_c_c c_p" @click="handleClick(scope.row)">下载</div> -->
        </template>
      </el-table-column>
    </el-table>
    <pagination :total="total" @handleCurrentChange="handleCurrentChange" @handleSizeChange="handleSizeChange" />
  </div>
</template>

<script>
import pagination from "@/components/pagination.vue";

export default {
  components: {
    pagination,
  },
  data() {
    return {
      tableData: [],
      total: 0,
      page: 1,
      value1: "",
      page_size: 10,
      form1: {
        kw: "",
        time_end: "",
        time_start: "",
      },
    };
  },
  created() {
    this.getEinvoice();
  },
  methods: {
    getEinvoice() {
      if (this.value1 && this.value1.length > 0) {
        let Atime = Date.parse(new Date(this.value1[0])) / 1000;
        let Btime = Date.parse(new Date(this.value1[1])) / 1000;
        if (Atime > Btime) {
          this.form1.time_end = Atime;
          this.form1.time_start = Btime;
        } else {
          this.form1.time_end = Btime;
          this.form1.time_start = Atime;
        }
      }
      this.$api("account.getEinvoice", {
        ...this.form1,
        page: this.page,
        page_size: this.page_size,
      }).then((res) => {
        this.tableData = res.data.list;
        this.total = res.listTotal;
      });
    },
    changeTime(e) {
      if (!e) {
        this.form1.time_end = "";
        this.form1.time_start = "";
      }
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getEinvoice();
    },
    handleSizeChange(val) {
      this.page_size = val;
      this.getEinvoice();
    },
    handleClick(row) {
      if (!row.download_url) return;
      window.open(row.download_url);
    },
  },
};
</script>

<style lang="less" scoped>
.el-icon-shopping-cart-2 {
  font-size: 21px;
  color: @themeColor;
  margin-right: 15px;
  cursor: pointer;
}
.el-icon-delete {
  font-size: 20px;
  color: #ff4c4c;
  cursor: pointer;
}
</style>
